import { useState, useEffect, useRef, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { parseISO, isPast, isFuture, isBefore, isEqual, startOfDay, endOfDay } from 'date-fns'
import i18n from 'i18n'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// KN Components
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNMap from 'components/KN_Molecules/KNMap/KNMap'
import TripMapFilters from './TripMapFilters'
import TripMapList from './TripMapList'

// Functional
import { analyticsPageView } from 'global/helpers/analytics'
import { TripListContext } from 'context/trips/TripListContext'
import { TripMapContext } from 'context/trips/TripMapContext'
import { getVehicles, getPositions } from 'screens/VehicleManager/VehicleManager.service'
import { getDrivers } from 'screens/DriverManager/DriverManager.service'
import {
  processDriversAndVehicles,
  groupStopsByLocation,
  getGroupedGeoPointsFromLegs,
  } from './TripDashboard.helpers'
import { getTrips, getLegsForTrips } from './TripDashboard.service'
import { getStopTooltip } from 'screens/TripDetails/SequenceMapView'
import { getTripVehiclePositions } from 'screens/TripDetails/TripDetails.service'
import { groupGeoPointsBySpeed, positionDataTransformer } from 'screens/TripDetails/TripDetails.helpers'

// Types
import { MapMarker, MapMarkerState, LocationGroupMapMarker, VehicleMapMarker, hasLocationGroup, hasVehicleData } from 'components/KN_Molecules/KNMap/types'
import { TripData, StopsLocationGroup } from './TripDashboard.types'
import { LegData, GeoPoint, GeoPointsGroup } from 'screens/TripDetails/TripDetails.types'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'
import theme from 'assets/theme'

const getLocationMarkers = (groups: StopsLocationGroup[]): LocationGroupMapMarker[] =>
  groups.reduce((markers: LocationGroupMapMarker[], group: StopsLocationGroup) => {
    markers.push({
      id: group.id,
      latitude: group.geoPoint.latitude,
      longitude: group.geoPoint.longitude,
      tooltip: getStopTooltip(group),
      locationGroup: group,
      cluster: 'markers',
    })
    return markers
  }, [])

const getVehicleMarkers = (trips: TripData[]): VehicleMapMarker[] =>
  Array.from(trips.sort((a, b) => {
    // sort by descending last tracked timestamp,
    // so the first created marker for given vehicle is always the latest one
    const firstDate = parseISO(a.lastTrackedTimestamp!)
    const secondDate = parseISO(b.lastTrackedTimestamp!)
    return isEqual(firstDate, secondDate) ? 0 : isBefore(firstDate, secondDate) ? 1 : -1
  }).reduce((markers: Map<string, VehicleMapMarker>, trip) => {
    // make sure there are no duplicated vehicle markers if given vehicle is assigned to multiple trips
    const markerId = trip.assignedVehicle!.licensePlate
    const currentMarker: VehicleMapMarker = markers.get(markerId) ?? {
      id: markerId,
      latitude: trip.lastTrackedPosition!.latitude,
      longitude: trip.lastTrackedPosition!.longitude,
      type: 'VEHICLE',
      tooltip: (
        <KNTypography variant="textLG_SB" color="primary.main">{trip.assignedVehicle!.licensePlate}</KNTypography>
      ),
      vehicle: trip.assignedVehicle!,
      tripCids: [],
      cluster: 'markers',
    }
    currentMarker.tripCids.push(trip.entityId)
    markers.set(markerId, currentMarker)
    return markers
  }, new Map<string, VehicleMapMarker>()).values())

const MapView = (): ReactElement => {
  const { t } = useTranslation()
  const [tripMapState, tripMapDispatch] = useContext(TripMapContext)
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const [displayVehicleTrace, setDisplayVehicleTrace] = useState(false)
  const [tripsData, setTripsData] = useState<TripData[]>([])
  const [filteredTripsData, setFilteredTripsData] = useState<TripData[]>([])
  const [legsData, setLegsData] = useState<LegData[]>([])
  const [filteredLegsData, setFilteredLegsData] = useState<LegData[]>([])
  const [markers, setMarkers] = useState<MapMarker[]>([])
  const [geoPoints, setGeoPoints] = useState<GeoPoint[]>([])
  const [groupedGeoPoints, setGroupedGeoPoints] = useState<GeoPointsGroup[]>([])
  const [loading, setLoading] = useState(true)
  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const [vehicles, drivers, trips] = await Promise.all([
      tripListState.vehiclesPreloaded ? tripListState.vehicles : getVehicles(),
      tripListState.driversPreloaded ? tripListState.drivers : getDrivers(),
      getTrips(),
    ])
    if (!tripListState.vehiclesPreloaded) {
      tripListDispatch({ type: 'setVehicles', payload: vehicles })
    }
    if (!tripListState.driversPreloaded) {
      tripListDispatch({ type: 'setDrivers', payload: drivers })
    }
    const validTrips = trips.map((trip) => processDriversAndVehicles(trip, vehicles, drivers))
      .filter((trip) => trip.tracked
        && isPast(startOfDay(parseISO(trip.earlyPickUpDate)))
        && isFuture(endOfDay(parseISO(trip.lateDeliveryDate)))
        && trip.assignedVehicle
        && trip.lastTrackedTimestamp
        && trip.lastTrackedPosition)
    const validTripCids = validTrips.map((trip) => trip.entityId)
    const validLegs = await getLegsForTrips(validTripCids)
    setTripsData(validTrips)
    setLegsData(validLegs)
    setLoading(false)
  }

  const clearVehicleMetaData = () => {
    setGeoPoints([])
    setGroupedGeoPoints([])
  }

  useEffect(() => {
    const fetchPositions = async (): Promise<void> => {
      if (tripMapState.relatedTripCids.length === 0) {
        return
      }
      // clear old data
      clearVehicleMetaData()
      setMarkers([])
      let vehiclePositions: GeoPoint[] = []
      for (const tripCid of tripMapState.relatedTripCids) {
        vehiclePositions = positionDataTransformer(await getTripVehiclePositions(tripCid))
      }
      const groupedVehiclePositions = groupGeoPointsBySpeed(vehiclePositions)
      setGeoPoints(vehiclePositions)
      setGroupedGeoPoints(groupedVehiclePositions)
    }
    if (displayVehicleTrace) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchPositions()
    } else {
      clearVehicleMetaData()
      setMarkers([])
    }
  }, [tripMapState.relatedTripCids, displayVehicleTrace])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
    analyticsPageView('polestar/cs/trips_map')
  }, [])

  useEffect(() => {
    const filteredTrips = getFilteredTripsData()
    const filteredTripCids = filteredTrips.map((trip) => trip.entityId)
    const filteredLegs = legsData.filter((leg) => filteredTripCids.includes(leg.tripCid!))
    setFilteredTripsData(filteredTrips)
    setFilteredLegsData(filteredLegs)
  }, [tripsData, legsData, tripMapState.filters])

  useEffect(() => {
    const newMarkers: MapMarker[] = [
      ...getLocationMarkers(groupStopsByLocation(filteredLegsData)),
      ...getVehicleMarkers(filteredTripsData),
    ].map((marker) => {
      marker.state = marker.id === tripMapState.activeLocationGroup ? MapMarkerState.Active : MapMarkerState.Default
      return marker
    })
    let relatedTripCids: string[] = []
    const activeMarker = newMarkers.find((marker) => marker.state === MapMarkerState.Active)
    if (activeMarker) {
      if (hasLocationGroup(activeMarker)) {
        const activeMarkerLegs = activeMarker.locationGroup.stopLegPairs.map((pair) => pair.leg)
        relatedTripCids = Array.from(new Set(activeMarkerLegs.map((leg) => leg.tripCid!))) // set used to get unique values only
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        clearVehicleMetaData()
      } else if (hasVehicleData(activeMarker)) {
        const tripsWithVehicle = tripsData.filter((trip) => trip.assignedVehicle!.id === activeMarker.vehicle.id)
        relatedTripCids = Array.from(new Set(tripsWithVehicle.map((trip) => trip.entityId))) // Set() used to get unique values only
        const activeMarkerLegs = legsData.filter((leg) => relatedTripCids.includes(leg.tripCid!))
      }
    } else {
      // no active markers, clear all meta data
      clearVehicleMetaData()
    }
    tripMapDispatch({
      type: 'setRelatedTripCids',
      payload: relatedTripCids,
    })
    setMarkers(newMarkers)
  }, [filteredTripsData, filteredLegsData, tripMapState.activeLocationGroup])

  useEffect(() => {
    // make unrelated markers less visible
    const newMarkers = markers.map((marker) => {
      // skip active markers, so state isn't overridden
      if (marker.state === MapMarkerState.Active) {
        return marker
      }
      if (hasLocationGroup(marker)) {
        const markerTripCids = marker.locationGroup.stopLegPairs.map((pair) => pair.leg.tripCid)
        marker.state = tripMapState.relatedTripCids.length > 0 && tripMapState.relatedTripCids.filter((tripCid) => markerTripCids?.includes(tripCid)).length === 0
          ? MapMarkerState.Muted
          : MapMarkerState.Default
      } else if (hasVehicleData(marker)) {
        marker.state = tripMapState.relatedTripCids.length > 0 && tripMapState.relatedTripCids.filter((tripCid) => marker.tripCids.includes(tripCid)).length === 0
          ? MapMarkerState.Muted
          : MapMarkerState.Default
      }
      return marker
    })
    setMarkers(newMarkers)
  }, [tripMapState.relatedTripCids])

  const handleOnChange = useCallback(async (updatedTrip: TripData): Promise<void> => {
    await fetchData()
  }, [tripListState])

  const handleMarkerClick = useCallback((marker: MapMarker, map) => {
    setDisplayVehicleTrace(false)
    if (hasLocationGroup(marker)) {
      if (marker.locationGroup?.stopLegPairs[0]) {
        const tripCid = marker.locationGroup?.stopLegPairs[0]?.leg?.tripCid
        if (tripCid) {
          refs.current[tripCid]?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
      tripMapDispatch({
        type: 'setActiveLocationGroup',
        payload: marker.id,
      })
    } else if (hasVehicleData(marker)) {
      if (marker.tripCids[0]) {
        refs.current[marker.tripCids[0]]?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
      tripMapDispatch({
        type: 'setActiveLocationGroup',
        payload: marker.id,
      })
    }
  }, [])

  const handleListOnChange = useCallback((tripCid: string, opening: boolean) => {
    tripMapDispatch({
      type: 'setRelatedTripCids',
      payload: opening ? [tripCid] : [],
    })
    setDisplayVehicleTrace(opening)
  }, [markers])

  const getFilteredTripsData = useCallback((): TripData[] =>
    tripsData.filter((trip) => {
      const lowercaseKeywords = tripMapState.filters.keywords?.map((keyword: string) => keyword.toLowerCase())
      let keywordsCondition = true
      if (lowercaseKeywords?.length) {
        const lowercaseValues = [
          trip.voyageNumber,
          trip.pickUpPlace,
          trip.countryCodePickUpPlace,
          trip.countryPickUpPlace ?? '',
          trip.deliveryPlace,
          trip.countryCodeDeliveryPlace,
          trip.countryDeliveryPlace ?? '',
          trip.assignedDriver?.name ?? '',
          trip.assignedVehicle?.licensePlate ?? '',
          trip.secondaryAssignedVehicle?.licensePlate ?? '',
          ...trip.shipmentNumbers.map((shipmentNumber: string) => shipmentNumber.toLowerCase()),
          ...trip.customerReferences,
        ].map((value: string) => value.toLowerCase())
        keywordsCondition = lowercaseValues
          .map((value) => {
            return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
          })
          .some((condition: boolean) => condition)
      }

      let statusCondition = true
      const statusFilters = tripMapState.filters.status
      if (statusFilters?.length) {
        statusCondition = statusFilters
          .map((status) => {
            return trip.status === status
          })
          .some((condition: boolean) => condition)
      }

      let relatedTripCondition = true
      if (tripMapState.filters.onlyRelatedTrips && tripMapState.relatedTripCids.length > 0) {
        relatedTripCondition = tripMapState.relatedTripCids.includes(trip.entityId)
      }

      return [keywordsCondition, statusCondition, relatedTripCondition].every((condition: boolean) => condition)
    }), [tripsData, tripMapState.filters, tripMapState.relatedTripCids])

  return (
    <Container
      data-test="trips-container"
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.cs.trip_dashboard.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <Stack spacing={0} direction="row" alignItems="stretch" sx={{ flexGrow: 1 }}>
          <Paper elevation={8} sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <KNMap
              markers={markers}
              geoPoints={geoPoints}
              groupedGeoPoints={groupedGeoPoints}
              onMarkerClick={handleMarkerClick}
              withHeading
            />
          </Paper>
          <Paper elevation={16} sx={{
            width: '360px',
            padding: 2,
            zIndex: 1000,
            maxHeight: 'calc(100vh - 2rem)',
            overflowY: 'scroll',
          }}>
            <Stack direction="column">
              <Stack spacing={1} direction="column" marginBottom={2}>
                <KNTypography data-test="trips-count" variant="h4">
                  {t('screens.cs.trip_dashboard.ongoing_trips_count', {
                    count: filteredTripsData.length,
                  })}
                </KNTypography>
                <TripMapFilters />
              </Stack>
              <TripMapList trips={filteredTripsData} onChange={handleListOnChange} refs={refs} />
            </Stack>
          </Paper>
        </Stack>
      )}
    </Container>
  )
}

export default MapView
